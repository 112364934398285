import React, {useEffect, useState} from "react";

export default function Header({screenWidth}) {

    return <header className="w-full fixed md:relative bg-[#19212b] flex flex-col min-h-12 px-4 md:h-28 md:px-12">
        <div className="w-full h-full flex justify-between items-center">
            <img src={'https://www.epsonrewards.my/wp-content/uploads/2024/06/Epson-Logo-White.png'} alt='Epson Logo' className="h-12 md:h-[60%] " />
            <p className="text-[#d9e3ec] text-lg h-full flex justify-center items-center px-2 cursor-default" >E-Rewards by Epson</p>
        </div>
    </header>
}
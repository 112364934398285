import React, {useEffect, useState, useRef} from "react";
import axios from "axios";
import Header from "../components/header";
import Footer from "../components/footer";
import UpArrow from '../images/up-arrow.svg';
import { useNavigate } from "react-router-dom";
import Banner1 from '../images/banner-1.jpg';
import TncPdf from '../pdf/Epson-New Year New Rewards 2025.pdf';

export default function Home({screenWidth}) {
    const navigate = useNavigate();
    const [formData, setFormData] = useState({
        mobileNum: '',
        name: '',
        email: '',
        sku: '',
        purchasedDate: '',
        purchasedValue: '',
        invoiceNo: '',
        invoiceCopy: null,
        productSerialNo: '',
        productSerialNoImg: '',
        productModel: '',
        productCategory: '',
        voucherValue: '',
        tnc: false,
        personalDataCollection: false,
        personalDataPolicy: false,
        marketingComms: false,
        homeOrOffice: '',
    });

    const [blurStates, setBlurStates] = useState({
        mobileNum: false,
        email: false,
        purchasedDate: false,
        purchasedValue: false,
        invoiceNo: false,
        invoiceCopy: false,
        productSerialNo: false,
        productSerialNoImg: false,
        homeOrOffice: false,
    });
    const [focus, setFocus] = useState('');
    const [canSubmit, setCanSubmit] = useState(false);
    const [backToTopVisible, setBackToTopVisible] = useState(false);
    const scrollContainerRef = useRef(null);
    const [remainingVoucher, setRemainingVoucher] = useState(0);
    const [eventDate, setEventDate] = useState({
        start: '', end: ''
    });
    const [priceRange, setPriceRange] = useState({start: 0, end: 0});
    const [redemptionPeriodEnded, setRedemptionPeriodEnded] = useState(false);
    const [isValidValue, setIsValidValue] = useState('');
    const [eventCode, setEventCode] = useState('');
    const [disableMobileNum, setDisableMobileNum] = useState(false);
    const [disableInvoiceCopy, setDisableInvoiceCopy] = useState(false);
    const [bannerIndex, setBannerIndex] = useState(0);
    const handleSubmit = async () => {
        try {
            const request = await axios.post(`https://api.epsonrewards.my/v1/epson/insertRedemption`, {
                'full_name': formData.name,
                'contact_no': formData.mobileNum,
                'email': formData.email,
                'serial_number': formData.productSerialNo,
                'category': formData.productCategory,
                'model': formData.productModel,
                'sku': formData.sku,
                'purchased_date': formData.purchasedDate,
                'purchased_value': formData.purchasedValue,
                'invoice_no': formData.invoiceNo,
                'invoice_no_img': formData.invoiceCopy,
                'serial_number_img': formData.productSerialNoImg,
                'event_code': eventCode,
                'promo_code': formData.ecoBearAR,
                'home_office_usage': formData.homeOrOffice,
            }, {
                headers: {
                    Authorization: `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX2lkIjoxMjMsImV4cCI6NDg4NzIyODgyNn0.vWUOB7q8p28_b1Yl0sRTPYRoefEZ5iOXhgjDHAhESlI`
                  }
            });
            
            if (request.data.status == 200) {
                navigate('/thank-you');
            } else if (request.data.status == 202) {
                alert("Invalid Redemption! This serial number has already been redeemed.");
            }

        } catch (error) {
            console.log(error);
        }
    }

    const handleSerialNo = (e) => {
        if (e.target.value.length <= 11){
            handleChange('productSerialNo', e.target.value);
        }
    }

    const handleBlur = (field) => {
        setFocus('');
        if (formData[field].trim() === '') {
            setBlurStates({...blurStates, [field]: true});
        } else {
            setBlurStates({...blurStates, [field]: false});
        }
    }

    const uploadFile = async (file, filepath) => {
        const data = new FormData();
        data.append("upload_type", "serial_no");
        data.append("fileToUpload", file, filepath);
        data.append("campaign_name", "Test1");
        data.append("upload_file_type", file.type.split('/')[1]);

        let url = '';

        const requestOptions = {
        method: "POST",
        body: data,
        redirect: "follow"
        };

        
        await fetch("https://api.epsonrewards.my/aws_handler/upload/upload_file.php", requestOptions)
        .then((response) => response.json())
        .then((result) => {console.log('result', result, result.uploaded_url) ; url = result.uploaded_url})
        .catch((error) => console.error('error', error));
        return url;
    }

    const handleChange = async (field, value, event=null) => {
        if (field === 'purchasedValue') {
            if (isNaN(value)) {
                return;
            } 
        } else if (field === 'productSerialNoImg') {
            console.log(event.target.files[0], event.target.value);
            if (event != null) {
                value = await uploadFile(event.target.files[0], event.target.value);
                console.log('productSerialNoImg', value);
            }
        }
        let temp = {...formData};
        temp[field] = value;
        setFormData(temp);
    }

    const scrollToTop = () => {
        scrollContainerRef.current.scrollTo({
          top: 0,
          behavior: "smooth",
        });
      };

      function has14DaysPassed(endDate) {
        const end = new Date(endDate);
        
        const now = new Date();
        
        // Calculate the difference in milliseconds
        const timeDifference = now - end;
        
        // Convert milliseconds to days (1000 milliseconds * 60 seconds * 60 minutes * 24 hours)
        const daysPassed = timeDifference / (1000 * 60 * 60 * 24);
        
        return daysPassed >= 14;
      }

      const checkValue = (value) => {
        const val = parseFloat(value);
        const start = parseFloat(priceRange.start);
        const end = parseFloat(priceRange.end);
        if ((formData.purchasedValue != '' && (val >= start && val <= end)) || value == '') {
            return true;
        }

        if (val < start) {
            return 'under';
        } else if (val > end) {
            return 'over';
        }
      }

    useEffect(()=>{
        if (formData.purchasedValue === '') {
            setIsValidValue('');
        }
        else if (formData.purchasedValue >= priceRange.start && formData.purchasedValue <= priceRange.end) {
            setIsValidValue('');
        } else if (formData.purchasedValue > priceRange.end) {
            setIsValidValue('over');
        } else if (formData.purchasedValue < priceRange.start) {
            setIsValidValue('under');
        }

        if (formData.mobileNum.trim() !== '' && formData.name.trim() !== '' && formData.email.trim() !== '' && formData.purchasedDate.trim() !== '' && formData.purchasedValue !== '' && formData.invoiceNo.trim() !== '' && formData.productSerialNo.trim() !== '' && formData.tnc && isValidValue === '' && formData.homeOrOffice !== '') {
            setCanSubmit(true);
        } else {
            setCanSubmit(false);
        }
    }, [formData]);

    useEffect(() => {
        const handleScroll = () => {
          if (scrollContainerRef.current.scrollTop > 500) {
            setBackToTopVisible(true);
          } else {
            setBackToTopVisible(false);
          }
        };
    
        // Add scroll event listener
        const scrollContainer = scrollContainerRef.current;
        scrollContainer.addEventListener("scroll", handleScroll);
    
        // Clean up the event listener on component unmount
        return () => {
            scrollContainer.removeEventListener("scroll", handleScroll);
        };
      }, []);

      useEffect(() => {
        async function decodeData() {
        const data = new URLSearchParams(window.location.search).get('data');
        const dataEncodedUrl = encodeURIComponent(data);

        if (data) {
            const request = await axios.get(`https://api.epsonrewards.my/v1/api-decryption?token=${dataEncodedUrl}`, 
                {
                    headers: {
                      Authorization: `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX2lkIjoxMjMsImV4cCI6NDg4NzIyODgyNn0.vWUOB7q8p28_b1Yl0sRTPYRoefEZ5iOXhgjDHAhESlI`
                    }
                }
            )
            const dataDecoded = atob(request.data.data.encrypted_details);
            const dataDecodedObj = JSON.parse(dataDecoded);
            return dataDecodedObj;
        } else {
            alert('Invalid Redemption! Please try again.');
            window.location.href = 'https://my.epson.com.my/';
        }
    }

    async function getData() {
        const data = await decodeData();
        console.log(data);

        if (data) {
            try {
                const request = await axios.post(`https://api.epsonrewards.my/v1/epson/checkRedemption`,
                    {
                        'sku': data.sku,
                        'serial_number': data.serial_number,
                        'event_code': data.event_code
                    },
                    {
                        headers: {
                          Authorization: `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX2lkIjoxMjMsImV4cCI6NDg4NzIyODgyNn0.vWUOB7q8p28_b1Yl0sRTPYRoefEZ5iOXhgjDHAhESlI`
                        }
                    }
                );
                console.log(request.data);
                if (request.data.data.redeem_status) {
                    const requestData = request.data.data.result[0];
                    console.log(requestData);

                    if (has14DaysPassed(requestData.event_end_date)) {
                        setRedemptionPeriodEnded(true);
                        return;
                    }

                    setFormData({...formData, name: data.name, mobileNum: data.contact_no, email: data.email, productSerialNo: data.serial_number, productCategory: requestData.category, productModel: requestData.model, productCategory: requestData.category, sku: data.sku, voucherValue: requestData.voucher_amount, purchasedDate: data.purchased_date, purchasedValue: parseFloat(data.purchased_value), invoiceCopy: data.invoice_no_img});

                    setRemainingVoucher(requestData.voucher_quantity - parseInt(requestData.redeemed_count, 10));

                    setEventDate({start: requestData.event_start_date, end: requestData.event_end_date});
                    
                    setPriceRange({start: parseFloat(requestData.redeem_price_range_start)
                        , end: parseFloat(requestData.redeem_price_range_end)
                    });

                    setEventCode(data.event_code);

                    setDisableMobileNum(data.contact_no === '' ? false : true);

                    setDisableInvoiceCopy(data.invoice_no_img === '' ? false : true);
                } else {
                    alert('Invalid Redemption! Please try again.');
                    return;
                }

            } catch (error) {
                alert('Invalid Redemption! Please try again!');
            }
        }

    }
    getData();
      }, []);

    return <div ref={scrollContainerRef} className="h-screen w-screen overflow-y-auto overflow-x-hidden">
        <Header screenWidth={screenWidth} />
        <div id='banner' className="w-full h-[22dvh] md:h-[80dvh] mt-12 md:mt-0 relative">
            <img src={Banner1} alt='banner' className="w-full h-full" />
        </div>
        { <div id='backToTopBtn' onClick={scrollToTop} className={`fixed rounded-lg bg-black bg-opacity-40 cursor-pointer right-6 bottom-2 size-12 flex items-center justify-center transition-transform duration-500 ease-in-out transform  ${backToTopVisible ? 'translate-y-0' : 'translate-y-[150%]'}`}><img src={UpArrow} alt='up-arrow' className="h-[50%]"/></div>}
        <div id='redemption-form-section'>
            <div className="bg-[#19212b] w-full text-[#d9e3ec] px-[5%] md:pl-[23%] flex flex-col py-8">
                {!redemptionPeriodEnded ? <>
                    <h2 className="text-3xl md:text-4xl font-bold mb-7 flex justify-start">Redemption Form</h2>
                {screenWidth >= 768 ? <p className="text-sm md:text-md flex justify-start text-start">Please fill in all the required field below marked with the asterisk (<span className="text-[#ff0000] align-middle inline-block">*</span>)</p> : <>
                    <p className="text-sm md:text-md flex justify-start text-start">Please fill in all the required field below marked </p><p className="text-start text-sm md:text-md">with the asterisk (<span className="text-[#ff0000] align-middle inline-block">*</span>)</p>
                </>}
                </> : 
                <>
                    <h2 className="text-xl md:text-2xl font-bold mb-7 text-start flex justify-start">THE REDEMPTION FOR E-REWARDS BY EPSON IS NOW CLOSED.</h2>
                    <p className="text-sm md:text-md flex justify-start text-start">Thank you for your support in Epson. Stay tuned for next promotion. </p>
                </>}
                
                
            </div>
            {!redemptionPeriodEnded && 
                <div className="bg-[#fdeb9f] w-full py-12 flex justify-center">
                <div className="bg-white w-[90%] md:w-[45%] p-8 rounded-2xl shadow-lg">
                    {/* <h1 className="text-[#1b98e0] text-md md:text-lg border-t-2 border-[#1b98e0] p-5">Year End Blitz (1st Jan - 31st Mar)</h1> */}
                    <div className="">
                    <div className="flex items-start flex-col">
                        <h2 className="mb-10 text-2xl font-semibold ">Personal Information</h2>
                        <div className="w-full text-start">
                            <p className={`font-semibold text-md mb-2 ${focus === 'mobileNum' ? 'text-black' : blurStates.mobileNum ? 'text-[#800000]' : ''}`}>Contact Number (Mobile) <span className="text-[#ff0000]">*</span></p>
                            <p className="text-sm opacity-65 mb-3">(Without "-")</p>
                            <input type='tel' disabled={disableMobileNum} onBlur={()=>handleBlur('mobileNum')} onFocus={()=>setFocus('mobileNum')} className={`w-full h-9 md:h-12 bg-[rgba(242,242,242,0.65)] shadow px-3 text-md ${blurStates.mobileNum ? 'border border-[#800000]' : ''}`} value={formData.mobileNum} onChange={e=>handleChange('mobileNum', e.target.value)}></input>
                            {blurStates.mobileNum && !(focus === 'mobileNum') && <p className="text-gray-400 font-semibold text-sm mt-2">This field is required.</p>}
                        </div>
                        <div className="mt-5 w-full text-start">
                            <p className={`font-semibold text-md mb-3 ${focus === 'name' ? 'text-black' : blurStates.name ? 'text-[#800000]' : ''}`}>Name (as per MyKad / Passport) <span className="text-[#ff0000]">*</span></p>
                            <input disabled={true} type='text' onBlur={()=>handleBlur('name')} onFocus={()=>setFocus('name')} className={`w-full  h-9 md:h-12 bg-[rgba(242,242,242,0.65)] shadow px-3 text-md ${blurStates.name ? 'border border-[#800000]' : ''}`} value={formData.name} onChange={e=>handleChange('name', e.target.value)}></input>
                            {blurStates.name && !(focus === 'name') && <p className="text-gray-400 font-semibold text-sm mt-2">This field is required.</p>}
                        </div>
                        
                        <div className="mt-5 w-full text-start">
                            <p className={`font-semibold text-md mb-3 ${focus === 'email' ? 'text-black' : blurStates.email ? 'text-[#800000]' : ''}`}>Email <span className="text-[#ff0000]">*</span></p>
                            <input disabled={true} type='email' onBlur={()=>handleBlur('email')} onFocus={()=>setFocus('email')} className={`w-full  h-9 md:h-12 bg-[rgba(242,242,242,0.65)] shadow px-3 text-md ${blurStates.email ? 'border border-[#800000]' : ''}`} value={formData.email} onChange={e=>handleChange('email', e.target.value)}></input>
                            {blurStates.email && !(focus === 'email') && <p className="text-gray-400 font-semibold text-sm mt-2">This field is required.</p>}
                        </div>
                    </div>
                    <div className="flex items-start flex-col mt-20">
                        <h2 className="mb-10 text-2xl font-semibold ">Product Details</h2>
                        <div className="w-full text-start">
                            <p className={`font-semibold text-md mb-3 ${focus === 'productSerialNo' ? 'text-black' : blurStates.productSerialNo ? 'text-[#800000]' : ''}`}>Product Serial Number <span className="text-[#ff0000]">*</span></p>
                            <input disabled={true} type='tel' onBlur={()=>handleBlur('productSerialNo')} onFocus={()=>setFocus('productSerialNo')} className={`w-full  h-9 md:h-12 bg-[rgba(242,242,242,0.65)] shadow px-3 text-md ${blurStates.productSerialNo ? 'border border-[#800000]' : ''}`} value={formData.productSerialNo} onChange={e=>handleSerialNo(e)}></input>
                            <p className="opacity-65 mt-2 text-sm">{formData.productSerialNo.length} of 11 max characters</p>
                        </div>
                        <div className={`mt-5 w-full text-start`}>
                            <p className={`font-semibold text-md mb-3 ${focus === 'purchasedDate' ? 'text-black' : blurStates.purchasedDate ? 'text-[#800000]' : ''}`}>Purchased Date <span className="text-[#ff0000]">*</span></p>
                            <input disabled={true} type='date' min={eventDate.start} max={eventDate.end} onBlur={()=>handleBlur('purchasedDate')} onFocus={()=>setFocus('purchasedDate')} className={`cursor-default w-full md:w-[30%] h-9 md:h-12 bg-[rgba(242,242,242,0.65)] shadow px-3 text-md ${focus === 'purchasedDate' ? 'text-black' : ''} ${blurStates.purchasedDate ? 'border border-[#800000] text-[#800000]' : ''}`} value={formData.purchasedDate} onChange={e=>handleChange('purchasedDate', e.target.value)}></input>
                            {blurStates.purchasedDate && !(focus === 'purchasedDate') && <p className="text-gray-400 font-semibold text-sm mt-2">This field is required.</p>}
                        </div>
                        <div className="mt-5 w-full text-start">
                            <p className={`font-semibold text-md mb-3 ${focus === 'purchasedValue' ? 'text-black' : blurStates.purchasedValue ? 'text-[#800000]' : ''}`}>Purchased Value (RM) <span className="text-[#ff0000]">*</span></p>
                            <input type='tel' required onBlur={()=>handleBlur('purchasedValue')} onFocus={()=>setFocus('purchasedValue')} className={`w-full  h-9 md:h-12 bg-[rgba(242,242,242,0.65)] shadow px-3 text-md ${blurStates.purchasedValue ? 'border border-[#800000]' : ''}`} value={formData.purchasedValue} onChange={e=>handleChange('purchasedValue', e.target.value)}></input>
                            {blurStates.purchasedValue && focus !== 'purchasedValue' && <p className="text-gray-400 font-semibold text-sm mt-2">This field is required.</p>}

                            {(isValidValue !== '') && formData.purchasedValue !== '' && <p className="text-[#ff0000] font-semibold text-sm mt-2">{isValidValue === 'under' ? 'Purchased value is under the minimum value.' : 'Purchased value is over the maximum value.'}</p>}
                        </div>
                        <div className="mt-5 w-full text-start">
                            <p className={`font-semibold text-md mb-3 ${focus === 'invoiceNo' ? 'text-black' : blurStates.invoiceNo ? 'text-[#800000]' : ''}`}>Invoice No <span className="text-[#ff0000]">*</span></p>
                            <input required type='text' onBlur={()=>handleBlur('invoiceNo')} onFocus={()=>setFocus('invoiceNo')} className={`w-full  h-9 md:h-12 bg-[rgba(242,242,242,0.65)] shadow px-3 text-md ${blurStates.invoiceNo ? 'border border-[#800000]' : ''}`} value={formData.invoiceNo} onChange={e=>handleChange('invoiceNo', e.target.value)}></input>
                            {blurStates.invoiceNo && focus !== 'invoiceNo' && <p className="text-gray-400 font-semibold text-sm mt-2">This field is required.</p>}
                        </div>
                        {remainingVoucher > 0 && <div className="mt-5 w-full text-start">
                            <p className="font-semibold text-md mb-1">Redemption</p>
                            <p className="text-md">Touch 'n Go eWallet Credit (RM {formData.voucherValue})</p>
                            <p className="text-md text-[#ff0000]">Remaining voucher balance: {remainingVoucher}</p>
                        </div>}
                    </div>
                    <div className="mt-5 w-full text-start">
                        <p className={`font-semibold text-md mb-1 ${(blurStates.tnc && !formData.tnc) || (blurStates.personalDataPolicy && !formData.personalDataPolicy) ? 'text-[#800000]' : ''}`}>Please let us know how you plan to use your purchase <span className="text-[#ff0000]">*</span></p>
                        <div className="flex text-md items-center">
                            <input onChange={e=>{handleChange('homeOrOffice', 'Home'); blurStates.homeOrOffice = true}} checked={formData.homeOrOffice === 'Home'} type='checkbox' className="size-3"/>
                            <p className={`text-sm md:text-md ml-2 ${blurStates.homeOrOffice && formData.homeOrOffice === "" ? 'text-[#800000]' : ''}`}><span className="font-bold">Home Use </span>- I plan to use this product for personal/residential purposes.</p>
                        </div>
                        <div className="flex text-md items-center mt-2">
                            <input onChange={e=>{handleChange('homeOrOffice', 'Office'); blurStates.homeOrOffice = true}} checked={formData.homeOrOffice === 'Office'} type='checkbox' className="size-3"/>
                            <p className={`text-sm md:text-md ml-2 ${blurStates.homeOrOffice && formData.homeOrOffice === "" ? 'text-[#800000]' : ''}`}><span className="font-bold">Office Use </span>- I plan to use this product in a professional/business setting.</p>
                        </div>
                    </div>
                    <div className="mt-5 w-full text-start">
                    <p className={`font-semibold text-md mb-1 ${(blurStates.tnc && !formData.tnc) || (blurStates.personalDataPolicy && !formData.personalDataPolicy) ? 'text-[#800000]' : ''}`}>Terms & Conditions <span className="text-[#ff0000]">*</span></p>
                        <div className="flex text-md items-center">
                            <input onChange={e=>{handleChange('tnc', !formData.tnc); blurStates.tnc = true}} checked={formData.tnc} type='checkbox' className="size-3"/>
                            <p className={`text-sm md:text-md ml-2 ${blurStates.tnc && !formData.tnc ? 'text-[#800000]' : ''}`}><span className="text-[#ff0000] leading-3">* </span>I agree to the campaign <a href={TncPdf} download={'Epson New Year New Rewards 2025 Terms & Conditions'} className="text-[#1b98e0]">Terms & Conditions</a></p>
                        </div>
                        <button className={`text-white text-md font-semibold rounded-lg w-full md:w-32 py-4 mt-8 shadow-lg ${!canSubmit ? 'bg-black opacity-30 cursor-default' : 'bg-[#e95095] cursor-pointer hover:bg-[#9966CC]'}`} onClick={handleSubmit} disabled={!canSubmit}>Submit</button>
                        </div>
                    </div>
                </div>
            </div>
            }
            
            <Footer />
        </div>
    </div>
}
import React from "react";

import Header from "../components/header";
import Footer from "../components/footer";

export default function ThankYou({screenWidth}) {
    
    return <div className='w-screen h-screen'>
        <Header screenWidth={screenWidth}/>
        <div className='flex flex-col items-center justify-center py-28 px-3 cursor-default'>
            <h1 className="text-4xl text-[#333333] mb-5">THANK YOU.</h1>
            <p>Thank you for your recent purchase of Epson products.</p>
            <p className="my-1">Your submission of rewards has been well received and will be processed within 21 Working Days.</p>
            <p>Once your submission is approved, your e-Voucher* will be sent to your registered email.</p>
            <button className="rounded-lg py-2 px-3 bg-[#19212b] text-white mt-7 text-lg font-semibold hover:bg-[#1e1e1e]" onClick={()=>window.location.href = "https://my.epson.com.my/"}>Back to MyEpson</button>
        </div>
        <div className="absolute bottom-0 w-screen">
            <Footer />
        </div>
    </div>
}